import { IconArrowRight } from 'components/icons/components/IconArrowRight'
import { RowItem } from 'components/ui-v2/RowItem'
import { ImageWithFallback } from 'components/ui/Image'
import { Text } from 'components/ui/Text'
import { PDP_TRAINING_SCHEDULE_APP } from 'constants/enum'
import { useTranslations } from 'next-intl'

export const PDPTrainingSchedule = () => {
  const t = useTranslations()

  return (
    <div>
      <a href={PDP_TRAINING_SCHEDULE_APP} target="_blank" rel="noreferrer">
        <RowItem
          className="from-info/80 to-info bg-gradient-to-r rounded-2xl px-4 py-3"
          leftIcon={
            <ImageWithFallback
              width="48"
              height="48"
              src="/img/calendar.png"
              fallback="/img/calendar.png"
              className="max-w-none"
              alt={t('pdp_training_schedule')}
            />
          }
          rightIcon={<IconArrowRight className="text-xl text-white" />}
          title={
            <Text
              fontSize="nm"
              v2TextColor="White"
              className="font-semibold capitalize"
            >
              {t('pdp_training_schedule')}
            </Text>
          }
          subtitle={
            <Text fontSize="xs" v2TextColor="White">
              {t('pdp_follow_now_to_never_miss_courses_info')}
            </Text>
          }
        />
      </a>
    </div>
  )
}
