import Image from 'next/image'
import { useState } from 'react'
import includes from 'utils/includes'

const isDev = !includes(
  ['api.droppii.com', 'api.droppii.us', 'api.droppii.vn'],
  process.env.BASE_URL,
)

export default function Avatar({
  src,
  className = 'w-10',
}: {
  src: string | null | undefined
  className?: string
}) {
  const [isError, setIsError] = useState(false)
  return (
    <div
      className={`${
        isError ? 'flex justify-center ring-4 ring-v2-gray-50' : ''
      } rounded-full bg-white overflow-hidden aspect-square ${className}`}
    >
      <div
        className={`relative aspect-square self-center ${
          isError ? 'w-[66%]' : 'w-full'
        }`}
      >
        <Image
          src={isError ? '/img/user-circle.svg' : src || ''}
          layout="fill"
          objectFit="cover"
          onError={() => {
            setIsError(true)
          }}
          unoptimized={isDev}
          priority={!isDev}
        />
      </div>
    </div>
  )
}
