import React, { cloneElement, Children, isValidElement } from 'react'
import cx from 'classnames'
import { useMergeRefs } from '@dwarvesf/react-hooks'
import { forwardRefWithAs } from 'utils/render'
import { Box, BoxProps } from '../Box'

export interface AspectRatioProps extends BoxProps {
  // `21/9`, `16/9`, `9/16`, `4/3`, `1.85/1`
  ratio?: number
  className?: string
}

export const AspectRatio = forwardRefWithAs<'div', AspectRatioProps>(
  (props, ref) => {
    const { children: childrenProps, className, ratio = 1, ...rest } = props
    const internalRef = React.useRef<HTMLDivElement>(null)
    const mergedRef = useMergeRefs(internalRef, ref)

    const children = Children.map(
      Children.toArray(childrenProps).filter(isValidElement),
      (child: React.ReactElement) =>
        cloneElement(child, {
          ...child.props,
          className: cx(
            'inset-0 absolute w-full h-full',
            child.props.className,
          ),
        }),
    )

    return (
      <Box
        ref={mergedRef}
        className={cx('h-0 relative', className)}
        style={{ paddingBottom: `${100 / ratio}%` }}
        {...rest}
      >
        {children}
      </Box>
    )
  },
)
