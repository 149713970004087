import * as React from 'react'
import { useMergeRefs } from '@dwarvesf/react-hooks'
import cx from 'classnames'
import { isSSR } from '@dwarvesf/react-utils'
import { AspectRatio, AspectRatioProps } from '../AspectRatio'
import { Skeleton } from '../Skeleton'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string
  aspectRatio?: AspectRatioProps['ratio']
  containerClassName?: string
}

export const Image = React.forwardRef(
  (props: ImageProps, ref?: React.Ref<HTMLImageElement>) => {
    const {
      src,
      aspectRatio,
      alt,
      containerClassName,
      fallback = '/img/fallback.png',
      className,
      ...rest
    } = props
    const internalRef = React.useRef<HTMLImageElement>(null)
    const mergedRef = useMergeRefs(internalRef, ref)
    const [started, setStarted] = React.useState(false)
    const [loading, setLoading] = React.useState(() => {
      if (isSSR()) {
        return true
      }

      const image = new window.Image()
      image.src = src || ''

      return !image.complete
    })

    React.useEffect(() => {
      const hasIntersectionObserver = !!window.IntersectionObserver
      let lazyImageObserver: IntersectionObserver
      if (hasIntersectionObserver && internalRef.current) {
        lazyImageObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const lazyImage = entry.target as HTMLImageElement
              if (lazyImage) {
                setStarted(true)
                lazyImageObserver.unobserve(lazyImage)
              }
            }
          })
        })

        lazyImageObserver.observe(internalRef.current)
      } else {
        setStarted(true)
      }

      return () => {
        lazyImageObserver?.disconnect()
      }
    }, [src])

    React.useEffect(() => {
      if (!started || !src) {
        return
      }

      const img = document.createElement('img')
      img.width = 0
      img.height = 0
      img.src = src

      function onload() {
        if (!internalRef.current) {
          return
        }
        if (src) {
          internalRef.current.src = src as string
        }
        setLoading(false)
        img.parentNode?.removeChild(img)
      }

      function onerror() {
        if (internalRef.current && fallback) {
          internalRef.current.src = fallback
          setLoading(false)
        }
      }

      img.addEventListener('load', onload)
      img.addEventListener('error', onerror)

      return function cleanup() {
        img.removeEventListener('load', onload)
        if (img) {
          img.parentNode?.removeChild(img)
        }
      }
    }, [src, fallback, started])

    return (
      <AspectRatio className={containerClassName} ratio={aspectRatio}>
        <Skeleton className={className} />
        <img
          className={cx(
            'transition duration-500 object-cover',
            {
              'opacity-0': loading,
            },
            className,
          )}
          ref={mergedRef}
          alt={alt}
          {...rest}
        />
      </AspectRatio>
    )
  },
)
