import { FeedLayout } from 'components/feed/Layout'
import { NewFeedListProvider } from 'components/feed/NewFeedList/context'
import NewFeedList from 'components/feed/NewFeedList'
import { Story } from 'components/feed/Story'
import { FloatMenuProductType } from 'components/product/FloatMenuProductType'
import { useDisclosure } from '@dwarvesf/react-hooks'
import { INSTALL_TELEGRAM_LINK, INSTALL_ZALO_LINK } from 'constants/enum'
import { IconSupport } from 'components/icons/components/IconSupport'
import { FeatureFlag } from 'flags'
import { TetHomeAlert } from 'components/tet/HomeAlert'
import { useFetchHolidayMetadata } from 'hooks/products/useFetchProductMetaData'
import { AcceptPolicyPanel } from 'components/feed/AcceptPolicyPanel'
import { useFetchUserAgreement } from 'hooks/feed/useFetchUserAgreement'
import { IS_KH_APP } from 'constants/dev'
import { PDPTrainingSchedule } from '../components/feed/PDPTrainingSchedule'

const HomePage = () => {
  const { isHaveHoliday } = useFetchHolidayMetadata()
  const { data: policies } = useFetchUserAgreement()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const supportType = IS_KH_APP
    ? [
        {
          key: 'telegram',
          label: '',
          icon: (
            <img
              src="/img/telegram-icon.svg"
              className="w-11 h-11"
              alt="telegram-icon"
            />
          ),
          onClick: () => {
            window.open(INSTALL_TELEGRAM_LINK, '_self')
          },
        },
      ]
    : [
        {
          key: 'zalo',
          label: '',
          icon: (
            <img
              src="/img/zalo-icon.svg"
              className="w-11 h-11"
              alt="zalo-icon"
            />
          ),
          onClick: () => {
            window.open(INSTALL_ZALO_LINK, '_self')
          },
        },
      ]

  return (
    <div className="bg-gray-500">
      <FeatureFlag flagKey="feed.required_accept_policy">
        {policies?.map(({ lastestVersion, policyType, isNeedCheck }, index) => (
          <AcceptPolicyPanel
            key={index}
            isOpen={isNeedCheck}
            version={lastestVersion}
            type={policyType}
          />
        ))}
      </FeatureFlag>
      <div className="space-y-2">
        <Story />

        <div className="bg-blue-10 p-4 space-y-3">
          {isHaveHoliday && <TetHomeAlert />}
          <FeatureFlag flagKey="common.pdp_malls">
            <PDPTrainingSchedule />
          </FeatureFlag>
          <NewFeedListProvider>
            <NewFeedList />
          </NewFeedListProvider>
        </div>
      </div>
      <FeatureFlag flagKey="feed.zalo_and_telegram_button">
        <FloatMenuProductType
          menuClassName="!bg-transparent !py-0 !space-y-0 !w-fit !right-[2px]"
          closeButtonClassName="!right-3"
          open={isOpen}
          menu={supportType}
          onClose={onClose}
          onOpen={onOpen}
          Icon={
            <div className="w-[26px] h-[26px] rounded-full bg-white flex justify-center items-center">
              <IconSupport className="text-primary" />
            </div>
          }
        />
      </FeatureFlag>
    </div>
  )
}

HomePage.Layout = FeedLayout
HomePage.hasNavbar = true

export default HomePage
